<template>
  <div class="container">
    <el-main>
      <div>
        <el-form :inline="true" :model="query" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="企业名称、信用代码"
                v-model="query.keyword"
                class="input-with-select"
                clearable
                @clear="search"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="search"
                class="general_bgc general_border cfff"
                >查询</el-button
              >
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div>
        <el-table border :data="tableData">
          <el-table-column label="序号" type="index" width="50" align="center">
          </el-table-column>
          <el-table-column
            label="企业名称"
            prop="companyName"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="统一社会信用代码"
            prop="creditCode"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="经营地址"
            prop="enterpriseStorage"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              {{
                scope.row.province +
                scope.row.city +
                scope.row.area +
                scope.row.town +
                scope.row.address
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="管理员人及联系方式"
            prop="enterpriseStorage"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.manageUser">
                <div>{{ scope.row.manageUser.userName }}</div>
                <div>{{ scope.row.manageUser.userMobilephone }}</div>
              </div>
              <div v-else style="color: #bbb">暂无管理员</div>
            </template>
          </el-table-column>
          <el-table-column
            label="评级"
            prop="enterpriseStorage"
            width="200"
            align="center"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.level"
                placeholder="请选择"
                @change="(data) => levelChange(data, scope.row.companyId)"
              >
                <el-option
                  v-for="item in scoreOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="i-page mt20 df aic" style="justify-content: flex-end">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="total"
          :current-page="query.page"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </el-main>
  </div>
</template>

<script>
import api from "../../../util/extra-api";

export default {
  data() {
    return {
      query: {
        limit: 10,
        page: 1,
        keyword: "",
      },
      scoreOptions: [
        {
          value: 0,
          label: "A:优秀",
        },
        {
          value: 1,
          label: "B:良好",
        },
        {
          value: 2,
          label: "C:一般",
        },
      ],
      tableData: [],
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    search() {
      this.query.page = 1;
      this.getList();
    },
    levelChange(level, companyId) {
      api.post(`/v1/pc/company/updateLevel`, {
        companyId,
        level,
      }).then((res) => {
        if (res.code === 200) {
            this.getList()
        }
      });
    },
    getList() {
      api.get(`/v1/pc/company/companyList`, this.query).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.map((v) => {
            if (!v.level) {
              v.level = 0;
            }
            return v;
          });
          this.total = res.total;
          console.log(res.data);
        }
      });
    },
    currentChange(page) {
      this.query.page = page;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  height: 100%;
}
.aic {
  align-items: center;
}
/deep/.el-table__empty-block {
  width: auto !important;
}
</style>
